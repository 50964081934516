import React from 'react';
import './App.css'; // Style file for responsiveness

const App = () => {
  // Links for each city
  const justDialLinks = {
    bilaspur: 'https://create.reviewus.in/Rate-Us/business_id=fzjtKsLKrIcJ6CoiGtiAbbsHvjxtBA',
    bhopal: 'https://create.reviewus.in/Rate-Us/business_id=QKk1vIpOtLQE0PGigqrCJkqd20Jv3L',
    raipur: 'https://create.reviewus.in/Rate-Us/business_id=JbeIxP7Sa4bDWdmnQCWfDNbovUJLm5',
    nagpur: 'https://create.reviewus.in/Rate-Us/business_id=v19xkJRSUUYRHjpqrjZadSL8QtNkN1',
    bhilai: 'https://create.reviewus.in/Rate-Us/business_id=fZg8VplNJ8ahHbZ7ss92t1eVBs22O6',
  };

  // Google Review link
  const googleReviewLink = 'https://create.reviewus.in/Rate-Us/business_id=dqn9aBIwxXjMsb1W4t4hK60E6NeZLO';

  return (
   
    <div className="app-container">
      {/* IT Care Logo */}
      <div className="logo-container">
        <img src="/images/it-care-logo.png" alt="IT Care Logo" className="logo" />
      </div>

      {/* Google Reviews Section */}
      <div className="google">
      <div className="google-review-section">
        <a href={googleReviewLink} target="_blank" rel="noopener noreferrer">
          <img src="/images/Google-Review-Logo.png" alt="Google Reviews" className="review-logo" />
        </a>
      </div>
      </div>

      {/* JustDial Logos for Different Cities */}
      <div className="justdial-logos">
        {Object.keys(justDialLinks).map((city) => (
          <div key={city} className="justdial-logo-container">
            <a href={justDialLinks[city]} target="_blank" rel="noopener noreferrer">
            <div className="justdial-content">

              <img src={`/images/justdial-${city}.png`} alt={`JustDial ${city}`} className="justdial-logo" />
              <span className="city-name">{city.charAt(0).toUpperCase() + city.slice(1)}</span> {/* Capitalize city name */}
             
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  
   
  );
};

export default App;
